import React from "react"
//import { ProductCard } from "react-ui-cards"
import ComingSoon from "react-coming-soon"

export default () => (

  <ComingSoon
      image="https://react-coming-soon.maksv.me/default-image.jpeg"
      bgColor="#fff"
      textColor="#000"
      illustration="development"
    />
  /*<div className='card-container'>
    <ProductCard
          photos={[
            'https://i.imgur.com/jRVDeI8.jpg',
            'https://i.imgur.com/raPe27t.jpg',
            'https://i.imgur.com/IpEsYSH.jpg'
          ]}
          price='$99-150'
          productName='Green Baby Alien'
          description='Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet.'
          buttonText='Add to cart'
          rating={3}
          url='https://github.com/nukeop'
        ></ProductCard>
        <ProductCard
          photos={[
            'https://i.imgur.com/jRVDeI8.jpg',
            'https://i.imgur.com/raPe27t.jpg',
            'https://i.imgur.com/IpEsYSH.jpg'
          ]}
          price='$99-150'
          productName='Force Bookshelf'
          description='Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet.'
          buttonText='Add to cart'
          rating={3}
          url='https://github.com/nukeop'
        ></ProductCard>
        <ProductCard
          photos={[
            'https://i.imgur.com/jRVDeI8.jpg',
            'https://i.imgur.com/raPe27t.jpg',
            'https://i.imgur.com/IpEsYSH.jpg'
          ]}
          price='$99-150'
          productName='Learning Tower'
          description='Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet.'
          buttonText='Add to cart'
          rating={3}
          url='https://github.com/nukeop'
        ></ProductCard>
        <ProductCard
          photos={[
            'https://i.imgur.com/jRVDeI8.jpg',
            'https://i.imgur.com/raPe27t.jpg',
            'https://i.imgur.com/IpEsYSH.jpg'
          ]}
          price='$99-150'
          productName='Busy Board'
          description='Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet.'
          buttonText='Add to cart'
          rating={3}
          url='https://github.com/nukeop'
        ></ProductCard>
        <ProductCard
          photos={[
            'https://i.imgur.com/jRVDeI8.jpg',
            'https://i.imgur.com/raPe27t.jpg',
            'https://i.imgur.com/IpEsYSH.jpg'
          ]}
          price='$99-150'
          productName='Custom 3D Print'
          description='Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet.'
          buttonText='Add to cart'
          rating={3}
          url='https://github.com/nukeop'
        ></ProductCard>

</div>*/
    ) 